import React from "react";
import fetchInitialData from "../server/utils/fetch-initial-data";
import buildAppVariables from "../server/utils/build-app-variables";
import { useSelector } from "react-redux";
// import Link from "next/link";
import { useRouter } from "next/router";
import NextHead from "next/head";

const Link = ({ href, children }) => {
  const router = useRouter();
  const clickHandler = () => {
    router.push(href);
  };
  return (
    <span style={{ cursor: "pointer" }} onClick={clickHandler}>
      {children}
    </span>
  );
};

const PageWrapper = (PageComponent) => {
  // Need to add checks on domain name
  const { domainName, reduxModules, pageId } = PageComponent;

  const PageWithSettings = (props) => {
    const breakpoint = useSelector((state) => state.breakpoint);

    return (
      <PageComponent
        breakpoint={breakpoint}
        Link={Link}
        NextHead={NextHead}
        {...props}
      />
    );
  };

  PageWithSettings.reduxModules = reduxModules;
  PageWithSettings.pageId = pageId;

  PageWithSettings.getInitialProps = async (context) => {
    try {
      context.appVariables = buildAppVariables(context);

      const siteData = await Promise.all([
        fetchInitialData(context), // to get base state & Header Footer data
        PageComponent.getInitialProps
          ? PageComponent.getInitialProps(context)
          : () => {}, // to get Home page data
      ]);
      return siteData && siteData.pageProps
        ? siteData.pageProps
        : { domainName };
    } catch (error) {
      //console.log("------------- getInitialProps error -----------");
      //error.isAxiosError ? console.log(error.toJSON()) : console.log(error);
      return {
        domainName,
      };
    }
  };

  return PageWithSettings;
};

export default PageWrapper;
