import { updateIntl } from "react-intl-redux";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import Cookies from "universal-cookie";

import { fetchHFState } from "@tw/header-footer/lib/redux/reducers";
import { updateAppVariables } from "../../redux/app-variables-reducer";
import { fetchAnnouncement } from "../../redux/announcement-reducer";

import {
  fetchLoginState,
  fetchTestState,
} from "@tw/customer-identity-cra/lib/login/redux/reducers";

const fetchInitialData = async (context) => {
  const appVariablesData = get(context, "appVariables");

  // to get user role for profile menu
  let login = context.req?.cookies || {};
  // console.log("-- get cookies --")
  // console.log(context.req?.cookies)
  // console.log(context.req?.headers?.cookie)

  if (isEmpty(login)) {
    // try to use cookies
    const c = new Cookies(context.req?.headers?.cookie || "");
    login = {
      ada: c.get("ada"),
      role: c.get("role"),
      refreshToken: c.get("refreshToken"),
      tokenExpires: c.get("tokenExpires"),
      accessToken: c.get("accessToken"),
      user: c.get("user"),
      loginType: c.get("loginType"),
      loginMemberType: c.get("loginMemberType"),
      isGuest: c.get("isGuest") === "true",
    };
  }

  await Promise.all([
    // context.store.dispatch(fetchAnnouncement(appVariablesData.languageId)),
    context.store.dispatch(fetchHFState()),
    //context.store.dispatch(fetchUserMenuState(role)),
    context.store.dispatch(fetchLoginState(login)),
  ]);

  updateBaseModules(context);
};

const updateBaseModules = async (context, siteData) => {
  if (context.store) {
    const appVariablesData = get(context, "appVariables"); // locale, languageId etc
    // const intlData = get(siteData, "intl");
    // const headerData = get(siteData, "headerState");
    // const footerData = get(siteData, "footerState");

    if (appVariablesData) {
      context.store.dispatch(updateAppVariables(appVariablesData));
    }

    // if (intlData) {
    //   context.store.dispatch(updateIntl(intlData));
    // }

    // if (headerData && !isEmpty(headerData)) {
    //   context.store.dispatch(setHeaderInitialData(headerData));
    // }

    // if (footerData && !isEmpty(footerData)) {
    //   context.store.dispatch(setFooterInitialData(footerData));
    // }
  }
};

export default fetchInitialData;
